import { isToday, isYesterday, parseISO } from 'date-fns';
import moment from 'moment';

export const DATE_FORMAT_SSMMHH_DDMMYYYY = 'HH:mm:ss DD/MM/YYYY';
export const DATE_FORMAT_MMHH_DDMMYYYY = 'HH:mm DD/MM/YYYY';
export const DATE_FORMAT_DDMMYYYY = 'DD/MM/YYYY';
export const DATE_FORMAT_YYYYMMDD = 'YYYY-MM-DD';
export const DATE_FORMAT_DDMMYYYYTHHMMSS = "YYYY-MM-DD'T'HH:mm:ss";
export const DATE_FORMAT_DDMMYYYYTHHMMSS_REPLACE = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_FORMAT_DDMMYYYYTHHMMSS_DAY_FILTER = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_FORMAT_DDMMYYYYTHHMM = 'DD-MM-YYYY HH:mm:ss';
export const DATE_FORMAT_MMMMDoYYYY = 'MMMM Do, YYYY';
export const DATE_FORMAT_dddd_MMMM_Do_YYYY = 'dddd DD/MM/YYYY';
export const DATE_FORMAT_DD_MM_YYYY_HH_MM = 'DD/MM/YYYY HH:mm';
export const DATE_FORMAT_HH_MM = 'HH:mm';

const formatDate = {
  getPresent: (date: string | Date) => {
    return moment(date).fromNow();
  },

  getDate: (date: string | Date) => {
    return moment(date).format('DD');
  },

  getMonth: (date: string | Date) => {
    return moment(date).format('MM');
  },

  format: (date: string | Date, formatString: string) => {
    return moment(date).format(formatString);
  },

  getDateString: (date: string) => {
    return moment(date).format('DD');
  },

  getMonthString: (date: string) => {
    return moment(date).format('MMM');
  },

  getHourString: (date: string) => {
    return moment(date).format('HHHH');
  },
};

export default formatDate;

export const formatDateString = (dateString: string) => {
  const date = parseISO(dateString);

  if (isToday(date)) {
    return `Today`;
  } else if (isYesterday(date)) {
    return `Yesterday`;
  } else {
    return moment(date).format(DATE_FORMAT_DDMMYYYY);
  }
};

export const formatTimeString = (dateString: string) => {
  const date = parseISO(dateString);
  return moment(date).format(DATE_FORMAT_HH_MM);
  // if(isToday(date)) {
  //   return `${moment(date).format('HH:mm')}`;
  // } else if (isYesterday(date)) {
  //   return `${moment(date).format('HH:mm')}`;
  // } else {
  //   return moment(date).format(DATE_FORMAT_HH_MM);
  // }
};

// export const formatTimeString = (dateString: string) => {
//   const date = parseISO(dateString);
//   const now = moment();
//   const duration = moment.duration(now.diff(date));

//   if (duration.asHours() < 1) {
//     // If less than 1 hour ago
//     return moment(date).fromNow();
//   } else if (duration.asHours() < 24) {
//     // If less than 24 hours ago
//     return moment(date).fromNow();
//   } else {
//     // Otherwise, format as 'HH:mm'
//     return moment(date).format('HH:mm');
//   }
// };
