import config from 'config';

export const BASE_URL = config.API_BASE || 'https://bp-apis.ssl.belga.be/belgapress/api';

export const PRESS_RELEASES_URL = `${BASE_URL}/public/pressreleases`;

export const NEWSROOMS_URL = `${BASE_URL}/public/newsrooms`;

export const TOPIC_URL = `${BASE_URL}/public/topics`;

export const USER_ACTIVITY_URL = `${BASE_URL}/public/useractivity`;
