import * as Sentry from '@sentry/nextjs';
import axios from 'axios';

import config from 'config';

const axiosClient = () => {
  const axiosOption = axios.create({
    baseURL: config.API_BASE,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  axiosOption.interceptors.request.use(
    async (config) => {
      return config;
    },
    (error) => {
      Sentry.captureException(error);
      Promise.reject(error);
    },
  );

  axiosOption.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      Sentry.captureException(error);
      if (error?.response?.status === 401) {
        throw error;
      } else {
        throw error;
      }
    },
  );

  return axiosOption;
};

export default axiosClient;
