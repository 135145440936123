export const QUERY_KEY = {
  LATEST_PRESS_RELEASES: 'LATEST_PRESS_RELEASES',
  NEWSROOMS: 'NEWSROOMS',
  TOPICS: 'TOPICS',
  PREVIOUS_PRESS_RELEASES: 'PREVIOUS_PRESS_RELEASES',
  PRESS_COVERAGES: 'PRESS_COVERAGES',
  UPCOMING_EVENTS: 'UPCOMING_EVENTS',
  UPCOMING_EVENTS_DETAIL: 'UPCOMING_EVENTS_DETAIL',
  DOCUMENT_AND_MEDIA_FOLDER: 'DOCUMENT_AND_MEDIA_FOLDER',
  DOCUMENT_AND_MEDIA_OTHER: 'DOCUMENT_AND_MEDIA_OTHER',
  DOCUMENT_AND_MEDIA_IMAGE: 'DOCUMENT_AND_MEDIA_IMAGE',
  DOCUMENT_AND_MEDIA_DOCUMENT: 'DOCUMENT_AND_MEDIA_DOCUMENT',
  DOCUMENT_AND_MEDIA_PRESS_RELEASES: 'DOCUMENT_AND_MEDIA_PRESS_RELEASES',
  DOCUMENT_AND_MEDIA_PRESS_RELEASES_ONLY: 'DOCUMENT_AND_MEDIA_PRESS_RELEASES_ONLY',
};
