import QueryString from 'qs';
import { IPressReleases, ListResponse, SearchParams } from 'types/types';
import { PRESS_RELEASES_URL } from './apiUrl';
import axiosClient from './axiosService';

export const pressReleasesService = {
  getPressReleases: async (params: SearchParams): Promise<ListResponse<IPressReleases>> => {
    return axiosClient()({
      url: PRESS_RELEASES_URL,
      method: 'GET',
      params,
      paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' }),
    })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  },
  getPressReleasesDetailById: async (id: string): Promise<IPressReleases> => {
    return axiosClient()({
      url: `${PRESS_RELEASES_URL}/${id}`,
      method: 'GET',
      headers: {
        'X-Belga-Context': 'BELGA_SHARE_PRESS_RELEASE',
      },
    })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  },

  getPressReleasesInNewsrooms: async (
    params: SearchParams,
  ): Promise<ListResponse<IPressReleases>> => {
    return axiosClient()({
      url: PRESS_RELEASES_URL,
      method: 'GET',
      params,
      paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' }),
    })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  },
};
