import { Theme } from '@emotion/react';
import { Box, Skeleton, SxProps, styled } from '@mui/material';
import { ReactNode } from 'react';

const ImageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '0px',
  paddingBottom: '56.25%' /* 16:9 ratio */,
  overflow: 'hidden',
}));

const ImageWidthSkeletonCustom = ({
  children,
  loaded,
  heightSkeleton,
  sxSkeleton,
}: {
  children: ReactNode;
  loaded: boolean;
  heightSkeleton: number | string;
  sxSkeleton?: SxProps<Theme>;
}) => {
  return (
    <ImageContainer>
      {!loaded && (
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={'100%'}
          height={heightSkeleton}
          sx={{ ...sxSkeleton }}
        />
      )}
      {children}
    </ImageContainer>
  );
};

export default ImageWidthSkeletonCustom;
